import React, { useEffect, useMemo } from "react";
import PortraitProgramLayout from "../PortraitProgramLayout";
import { useAuthContext } from "../../../context/auth";
import dayjs from "dayjs";

function PortraitDemoA() {
  const { circuitProgram, weightProgram, startTime, did, emitter } =
    useAuthContext();

  const videoRefs = React.useRef<(HTMLVideoElement | null)[]>([]);
  useEffect(() => {
    setTimeout(() => {
      videoRefs.current?.forEach((video) => video?.play());
    }, startTime.current - dayjs().valueOf());
  }, [startTime]);

  const areaMovements = useMemo(() => {
    const movements = circuitProgram?.areaAMovements ?? [];
    if (did === "R1" || did === "L1") {
      return movements.slice(0, 3);
    }
    if (did === "R6" || did === "L6") {
      return movements.slice(3, 6).reverse();
    }
    return [];
  }, [circuitProgram, did]);

  // useEffect(() => {
  //   emitter.current.on("resume", (startTime) => {
  //     setTimeout(() => {
  //       videoRefs.current?.forEach((video) => video?.play());
  //     }, startTime - dayjs().valueOf());
  //   });
  //   emitter.current.on("pause", (startTime) => {
  //     setTimeout(() => {
  //       videoRefs.current?.forEach((video) => video?.pause());
  //     }, startTime - dayjs().valueOf());
  //   });
  //   return () => {
  //     emitter.current.off("resume");
  //     emitter.current.off("pause");
  //   };
  // }, []);

  return (
    <PortraitProgramLayout>
      <div className="flex flex-col w-full h-full">
        {weightProgram &&
          weightProgram.firstHalfMovements.map((movement, index) => (
            <div className="flex-1 flex border-b border-border">
              <div className="flex justify-center items-center bg-bg w-[214px]">
                <h1 className="text-[96px] font-bold gradient-text">
                  {movement.did}
                </h1>
              </div>
              <div className="flex-1 relative">
                <div className="absolute top-[30px] right-[30px] flex flex-col items-center">
                  <h1
                    className="text-[96px] leading-[100px]"
                    style={{
                      color: movement.color,
                    }}
                  >
                    {movement.repeatCount}
                  </h1>
                  <p
                    className="text-[28px]"
                    style={{
                      color: movement.color,
                    }}
                  >
                    REPS
                  </p>
                </div>
                <video
                  className="w-full h-full object-cover"
                  ref={(el) => (videoRefs.current[index] = el)}
                  loop
                  muted
                  key={movement.movement.video}
                >
                  <source src={movement.movement.video} type="video/mp4" />
                </video>
              </div>
            </div>
          ))}
        {areaMovements.map((movement, index) => (
          <div className="flex-1 flex border-b border-border">
            <div className="flex justify-center items-center bg-bg w-[214px]">
              <h1 className="text-[96px] font-bold gradient-text">
                {did === "L1" ? `L${index + 1}` : `L${6 - index}`}
              </h1>
            </div>
            <div className="flex-1 relative">
              <video
                className="w-full h-full object-cover"
                ref={(el) => (videoRefs.current[index] = el)}
                loop
                muted
                key={movement.video}
              >
                <source src={movement.video} type="video/mp4" />
              </video>
            </div>
          </div>
        ))}
      </div>
    </PortraitProgramLayout>
  );
}

export default PortraitDemoA;
