import React, { useEffect, useState } from "react";
import PortraitProgramLayout from "../PortraitProgramLayout";
import { useAuthContext } from "../../../context/auth";
import cn from "../../../utils/cn";
import { TimerRenderer, useTimer } from "react-use-precision-timer";
import Lottie from "react-lottie";
import animation from "../../loading-animation.json";

const CHARACTERS = [
  "/characters/1.png",
  "/characters/2.png",
  "/characters/3.png",
  "/characters/4.png",
];

const ROWS = {
  "300m": 300,
  "600m": 600,
  "1200m": 1200,
};

const DID_TO_CHARACTER = {
  L1: 0,
  L6: 1,
  R1: 2,
  R6: 3,
};

const DID_TO_SIZE = {
  L1: {
    width: "670px",
    height: "820px",
  },
  L6: {
    width: "534px",
    height: "670px",
  },
  R1: {
    width: "562px",
    height: "660px",
  },
  R6: {
    width: "670px",
    height: "788px",
  },
};

function PortraitRow() {
  const timer = useTimer({
    fireOnStart: false,
    startImmediately: false,
  });
  const [rank, setRank] = useState<number>();

  const { did, rowProgressData, rowConnected, row, sendRowConnect } =
    useAuthContext();
  useEffect(() => {
    sendRowConnect();
  }, [sendRowConnect]);
  useEffect(() => {
    if (
      (
        Object.keys(rowProgressData) as Array<keyof typeof rowProgressData>
      ).some((key) => rowProgressData[key] > 0) &&
      !timer.isStarted()
    ) {
      timer.start();
    }
    if (
      rowProgressData[did as "L1" | "L6" | "R1" | "R6"] >=
        ROWS[row as "300m" | "600m" | "1200m"] &&
      !timer.isPaused()
    ) {
      setRank((prev) =>
        prev === undefined
          ? (
              Object.keys(rowProgressData) as Array<
                keyof typeof rowProgressData
              >
            ).filter(
              (key) =>
                rowProgressData[key] >= ROWS[row as "300m" | "600m" | "1200m"]
            ).length
          : prev
      );
      timer.pause();
    }
  }, [rowProgressData, did, timer, row]);

  return (
    <PortraitProgramLayout hiddenTitle>
      <div className="h-full w-full flex flex-col items-center justify-end bg-bg relative">
        {!rowConnected[did as "L1" | "L6" | "R1" | "R6"] && (
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 w-[512px] h-[512px]">
            <Lottie
              options={{
                loop: true,
                animationData: animation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width={512}
              height={512}
              isClickToPauseDisabled
            />
          </div>
        )}
        <div className="flex flex-col items-center gap-[80px] mb-[160px]">
          {rank && (
            <h1 className="text-[100px] font-extrabold text-white">{rank}위</h1>
          )}
          <h1 className="gradient-text text-[100px] font-extrabold leading-[120px]">
            {Math.min(
              rowProgressData[did as "L1" | "L6" | "R1" | "R6"],
              ROWS[row as "300m" | "600m" | "1200m"]
            ).toFixed(0)}{" "}
            m
          </h1>
          <TimerRenderer
            timer={timer}
            render={(t) => (
              <p className="text-[80px] font-extrabold text-white leading-[100px]">
                {String(
                  Math.floor(t.getElapsedRunningTime() / 1000 / 60)
                ).padStart(2, "0")}
                :
                {String(
                  Math.floor((t.getElapsedRunningTime() / 1000) % 60)
                ).padStart(2, "0")}
              </p>
            )}
            renderRate={500} // In milliseconds
          />

          <div className="h-[820px] w-[670px] flex justify-center items-end">
            <img
              src={
                CHARACTERS[DID_TO_CHARACTER[did as "L1" | "L6" | "R1" | "R6"]]
              }
              alt="character"
              className={cn(
                "self-end h-[820px] w-[670px]",
                !rowConnected[did as "L1" | "L6" | "R1" | "R6"] &&
                  "mix-blend-luminosity"
              )}
            />
          </div>
        </div>
      </div>
    </PortraitProgramLayout>
  );
}

export default PortraitRow;
