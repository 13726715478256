import React from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import BottomNav from "../BottomNav";
import { PeopleIcon } from "../../Icons";
import { useAuthContext } from "../../../context/auth";

function LandscapeTeamWorkout() {
  const { circuitProgram, weightProgram } = useAuthContext();
  const program = circuitProgram ?? weightProgram;

  return (
    <LandscapeProgramLayout step={5}>
      <div className="h-full w-full flex relative">
        <div className="px-[60px] pt-[134px] flex flex-col">
          <div className="flex items-center">
            <h1 className="gradient-text text-[70px] font-bold">
              TEAM WORK OUT
            </h1>
          </div>
          {program?.teamWorkOuts.map((workout, index) => (
            <div
              className="flex gap-8 text-[55px] font-bold text-white mt-6"
              key={index}
            >
              <p>{index + 1}</p>
              <p>{workout.context}</p>
            </div>
          ))}
        </div>
        <img
          src="teamworkout.png"
          alt="teamworkout"
          className="h-full absolute right-0 top-1/2 -translate-y-1/2"
        />
      </div>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default LandscapeTeamWorkout;
