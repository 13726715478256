// import { Program } from "../types";
// import instance from "./instance";

import { BaseProgram, CircuitProgram, WeightProgram } from "../types";
import instance from "./instance";

export const getProgram = async (programId: string) => {
  const { data } = await instance.get<CircuitProgram | WeightProgram>(
    `/program/programs/${programId}`
  );
  return data;
};

export const getMonthlyPrograms = async (year: number, month: number) => {
  const { data } = await instance.get<BaseProgram[]>(
    `/program/programs?year=${year}&month=${month}`
  );
  return data;
};
