import React, { useEffect } from "react";
import LandscapeProgramLayout from "./Programs/LandscapeProgramLayout";
import CircularProgress from "./CircularProgress";
import useCountDown from "../hooks/useCountDown";
import BottomNav from "./Programs/BottomNav";
import { useAuthContext } from "../context/auth";
import dayjs from "dayjs";
import GredientCircularProgress from "./GredientCircularProgress";

function Rest({ time = 60, nowStep }: { time?: number; nowStep: number }) {
  const { startTime, sendSetStep, step } = useAuthContext();
  const { count, reset, start, stop } = useCountDown(
    time,
    () => {
      sendSetStep(step + 1);
    },
    true
  );

  useEffect(() => {
    start();
  }, [startTime, start]);

  return (
    <LandscapeProgramLayout step={nowStep}>
      <div className="bg-bg w-full flex-1 flex justify-center">
        <div className="flex flex-col items-center mt-[124px] gap-3">
          <h1 className="text-[100px] font-bold gradient-text">READY</h1>
          <div className="w-[432px] h-[432px]">
            <GredientCircularProgress maxValue={60} value={count} />
          </div>
        </div>
      </div>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default Rest;
