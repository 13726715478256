import React from "react";
import { PersonIcon } from "./Icons";
import cn from "../utils/cn";

function Input(
  props: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & {
    icon?: React.ReactNode;
  }
) {
  return (
    <div className="bg-[#1F2838] rounded-[4px] border-[#424F67] border-2 p-6 flex gap-[10px] items-center">
      {props.icon && props.icon}
      <input
        {...props}
        className={cn(
          "bg-transparent text-[32px] flex-1 text-white",
          props.className
        )}
      />
    </div>
  );
}

export default Input;
