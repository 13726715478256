import React from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import BottomNav from "../BottomNav";
import { useAuthContext } from "../../../context/auth";
import useCountDown from "../../../hooks/useCountDown";
import CircuitDemo from "./CircuitDemo";
import WeightDemo from "./WeightDemo";
import NewCircuitDemo from "./NewCircuitDemo";

function LandscapeDemo() {
  // const [type, setType] = React.useState<"circuit" | "weight" | "new-circuit">(
  //   "new-circuit"
  // );

  const { circuitProgram, weightProgram } = useAuthContext();

  // useCountDown(
  //   180,
  //   () => {
  //     sendSetStep(step + 1);
  //   },
  //   true
  // );

  const getScreen = () => {
    return circuitProgram?.programType === "BOOSTER" ? (
      <CircuitDemo />
    ) : circuitProgram?.programType === "CIRCUIT" ? (
      <NewCircuitDemo />
    ) : (
      <WeightDemo />
    );
  };

  return (
    <LandscapeProgramLayout step={3}>
      <div className="h-full">
        <div className="flex h-full">
          <div
            className="flex-1 h-full flex"
            style={{
              background: `url("./Trainer-Girl.png")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {getScreen()}
          </div>
        </div>
      </div>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default LandscapeDemo;
