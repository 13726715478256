import instance from "./instance";

export const getRowing = async () => {
  const { data } = await instance.get(`/rowing-machine`);
  return data as {
    rowingMachineIds: string[];
  };
};

export const addRowing = async (rowingMachineIds: string[]) => {
  await instance.post(`/rowing-machine`, { rowingMachineIds });
};
