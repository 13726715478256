import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import styled from "styled-components";

const Container = styled(CircularProgressbar)`
  .CircularProgressbar-trail {
    stroke: #eff0f5 !important;
  }

  .CircularProgressbar-text {
    fill: black !important;
    font-size: 24px !important;
  }
`;

function GradientSVG({ startColor, endColor, idCSS, rotation }: any) {
  let gradientTransform = `rotate(${rotation})`;
  return (
    <svg style={{ height: 0 }}>
      <defs>
        <linearGradient id={"gradient"} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  );
}
function CircularProgress({
  value,
  maxValue,
}: {
  value: number;
  maxValue: number;
}) {
  return (
    <>
      <Container value={value} maxValue={maxValue} text={`${value}`} />
      <GradientSVG
        startColor={value <= 3 ? "#FF5757" : "#BFFF00"}
        endColor={value <= 3 ? "#FF5757" : "#00D7B0"}
        rotation="0"
      />
    </>
  );
}

export default CircularProgress;
