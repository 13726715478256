import React, { useEffect, useRef, useState } from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import BottomNav from "../BottomNav";
import cn from "../../../utils/cn";
import { Pause, Triangle } from "../../Icons";
import useCountDown from "../../../hooks/useCountDown";
import { useAuthContext } from "../../../context/auth";
import animation from "../../animation.json";
// @ts-ignore
import styles from "./WorkoutType2.module.css";

// @ts-ignore
import useSound from "use-sound";
// @ts-ignore
import countdown from "../../../countdown.mp3";
import Lottie from "react-lottie";

function Round({
  number,
  active,
  callback,
  complete,
  handleShowNext,
}: {
  number: number;
  active: boolean;
  complete: boolean;
  callback: () => void;
  handleShowNext: () => void;
}) {
  const { did } = useAuthContext();
  const [repeatCount, setRepeatCount] = useState(() => (number === 1 ? 1 : 0));
  const [count, setCount] = useState(() => (number === 1 ? 40 : 20));
  const [play] = useSound(countdown, {
    volume: 1,
  });
  useEffect(() => {
    if (active) {
      const timer = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount === 0) {
            const nowRepeatCount = repeatCount;
            if (nowRepeatCount === 5) {
              // handleShowNext();
              callback();
              return 40;
            }
            setRepeatCount((prev) => prev + 1);
            return nowRepeatCount % 2 === 1 ? 20 : 40;
          }
          if (prevCount === 4) {
            did === "1" && play();
          }
          return prevCount - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [active, repeatCount, callback, play]);

  if (complete) {
    return <img src={`/logos/${number}.png`} alt="workout" />;
  }
  return (
    <div
      className={cn(
        "flex items-center justify-center flex-col gap-[10px]",
        active && "bg-[#FF5757]",
        "border-b border-border",
        (number === 2 || number === 5) && "border-l border-r border-border",
        repeatCount % 2 === 0 && repeatCount > 1 && styles.blinking
      )}
    >
      <h1
        className={cn(
          "text-[120px] font-bold text-white opacity-50",
          active && "text-black opacity-100"
        )}
      >
        {number} R
      </h1>
      {active && (
        <>
          <p className="text-[50px] text-black font-bold">
            {repeatCount % 2 === 0
              ? "휴식"
              : `${Math.floor(repeatCount / 2) + 1} FIT`}
          </p>
          <p className="text-[100px] text-white">{count}</p>
        </>
      )}
    </div>
  );
}

function WorkoutType2({ second }: { second?: boolean }) {
  const [step, setStep] = useState(0);
  const { sendSetStep, step: totalStep, did } = useAuthContext();
  const [showNext, setShowNext] = useState(false);
  const handleShowNext = () => {
    setShowNext(true);
    setTimeout(() => {
      setShowNext(false);
    }, 10000);
  };

  return (
    <LandscapeProgramLayout step={4}>
      <div className={cn("bg-bg w-full relative h-[883px]")}>
        {showNext && (
          <div className="absolute w-full h-full pt-[180px] flex flex-col items-center bg-bg z-50 gap-[80px]">
            <h1 className="text-[100px] font-extrabold leading-[120px] gradient-text uppercase">
              next station
            </h1>
            <Lottie
              options={{
                loop: true,
                animationData: animation,
                rendererSettings: {
                  preserveAspectRatio: "xMidYMid slice",
                },
              }}
              width={710}
              height={330}
              isClickToPauseDisabled
            />
          </div>
        )}
        <img
          className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-10 w-[1389px] h-[682px] z-10"
          src="/logo.png"
          alt="workout"
        />
        <div className="grid grid-cols-3 grid-rows-2 z-20 relative h-full">
          {Array(6)
            .fill(0)
            .map((_, i) => (
              <Round
                active={i === step}
                complete={i < step}
                number={i + 1}
                handleShowNext={handleShowNext}
                callback={() => {
                  if (i === step)
                    setStep((prev) => {
                      if (prev === 5) {
                        setTimeout(() => {
                          console.log("dd");
                          sendSetStep(totalStep + 1);
                        }, 3000);
                        return prev + 1;
                      }
                      if (i === prev) {
                        handleShowNext();
                        return prev + 1;
                      }
                      return prev;
                    });
                }}
              />
            ))}
        </div>
      </div>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default WorkoutType2;
