import React, { useState } from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import BottomNav from "../BottomNav";
import { useAuthContext } from "../../../context/auth";

function IceBreaking() {
  const [start, setStart] = useState(false);
  const { circuitProgram, weightProgram, timer } = useAuthContext();
  const program = circuitProgram ?? weightProgram;
  return (
    <LandscapeProgramLayout step={1}>
      <div className="w-full flex-1 flex flex-col justify-center items-center">
        <div
          className="relative w-full h-full"
          style={{
            backgroundImage: `url("./main.png")`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <BottomNav />
      </div>
    </LandscapeProgramLayout>
  );
}

export default IceBreaking;
