import React from "react";
import PortraitProgramLayout from "../PortraitProgramLayout";
import { useAuthContext } from "../../../context/auth";

function PortraitTeamWorkout() {
  const { circuitProgram, weightProgram } = useAuthContext();
  const program = circuitProgram ?? weightProgram;
  return (
    <PortraitProgramLayout>
      <div className="h-full w-full flex flex-col items-center bg-bg">
        <div className="flex flex-col w-[795px]">
          <h1 className="gradient-text text-[100px] font-bold mt-[220px]">
            TEAM
            <br />
            WORK OUT
          </h1>
          <div className="flex flex-col">
            {program?.teamWorkOuts.map((workout, index) => (
              <div
                className="flex gap-8 text-[80px] font-bold text-white"
                key={index}
              >
                <p>{index + 1}</p>
                <p>{workout.context}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PortraitProgramLayout>
  );
}

export default PortraitTeamWorkout;
