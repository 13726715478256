import React from "react";

const DemoImage = () => (
  <svg
    width="500"
    height="162"
    viewBox="0 0 500 162"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="2"
      y="22"
      width="496"
      height="96"
      rx="48"
      stroke="url(#paint0_linear_5236_15573)"
      stroke-width="4"
    />
    <rect
      x="4"
      y="28"
      width="366"
      height="84"
      rx="42"
      fill="url(#paint1_linear_5236_15573)"
    />
    <rect
      x="286"
      y="28"
      width="84"
      height="84"
      rx="42"
      fill="url(#paint2_linear_5236_15573)"
    />
    <path
      d="M253.414 161.414C252.633 162.195 251.367 162.195 250.586 161.414L237.858 148.686C237.077 147.905 237.077 146.639 237.858 145.858C238.639 145.077 239.905 145.077 240.686 145.858L252 157.172L263.314 145.858C264.095 145.077 265.361 145.077 266.142 145.858C266.923 146.639 266.923 147.905 266.142 148.686L253.414 161.414ZM250 160L250 156.923L254 156.923L254 160L250 160ZM250 150.769L250 144.615L254 144.615L254 150.769L250 150.769ZM250 138.462L250 132.308L254 132.308L254 138.462L250 138.462ZM250 126.154L250 120L254 120L254 126.154L250 126.154ZM250 113.846L250 107.692L254 107.692L254 113.846L250 113.846ZM250 101.538L250 95.3846L254 95.3846L254 101.538L250 101.538ZM250 89.2308L250 83.0769L254 83.0769L254 89.2308L250 89.2308ZM250 76.9231L250 70.7692L254 70.7692L254 76.9231L250 76.9231ZM250 64.6154L250 58.4615L254 58.4615L254 64.6154L250 64.6154ZM250 52.3077L250 46.1538L254 46.1538L254 52.3077L250 52.3077ZM250 40L250 33.8461L254 33.8461L254 40L250 40ZM250 27.6923L250 21.5385L254 21.5385L254 27.6923L250 27.6923ZM250 15.3846L250 9.23079L254 9.23079L254 15.3846L250 15.3846ZM250 3.07695L250 -1.74846e-07L254 1.74846e-07L254 3.07695L250 3.07695Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_5236_15573"
        x1="0"
        y1="20"
        x2="38.4615"
        y2="212.308"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BFFF00" />
        <stop offset="1" stop-color="#00D7B0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5236_15573"
        x1="370"
        y1="70.0477"
        x2="4"
        y2="70.0477"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2CDA00" />
        <stop offset="1" stop-color="#2CDA00" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5236_15573"
        x1="286"
        y1="28"
        x2="370"
        y2="112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BFFF00" />
        <stop offset="1" stop-color="#00D7B0" />
      </linearGradient>
    </defs>
  </svg>
);
const WeightDemo = () => {
  return (
    <div className="w-full">
      <h3 className="gradient-text text-[50px] font-extrabold text-center mt-[80px]">
        WEIGHT TRAINING
      </h3>
      <div className="mt-[159px] flex justify-between items-center px-[200px]">
        <div className="flex flex-col gap-[60px] items-center">
          <div className="flex gap-[80px] items-center">
            <div className="flex flex-col items-center">
              <p className="gradient-text text-[32px] font-bold">전반전</p>
              <h1 className="text-[60px] font-extrabold leading-[80px] mt-[40px] text-white">
                1R - 4set
              </h1>
              <h1 className="text-[60px] font-extrabold leading-[80px] mt-[12px] text-white">
                2R - 4set
              </h1>
            </div>
            <div className="w-[6px] h-[220px] bg-[#ffffff99]"></div>
            <div className="flex flex-col items-center">
              <p className="gradient-text text-[32px] font-bold">후반전</p>
              <h1 className="text-[60px] font-extrabold leading-[80px] mt-[40px] text-white">
                1R - 4set
              </h1>
              <h1 className="text-[60px] font-extrabold leading-[80px] mt-[12px] text-white">
                2R - 4set
              </h1>
            </div>
          </div>
          <div className="flex gap-4 items-center">
            <p className="text-[80px] font-bold text-white leading-[90px]">
              1R
            </p>
            <p className="text-[80px] font-bold text-white leading-[90px]">-</p>
            <p className="text-[80px] font-bold gradient-text leading-[90px]">
              8MIN
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-[30px]">
          <DemoImage />
          <p className="text-[50px] font-extrabold gradient-text leading-[70px]">
            파트너와 교대
          </p>
        </div>
      </div>
    </div>
  );
};

export default WeightDemo;
