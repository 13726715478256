import React from "react";

const CircuitDemo = () => {
  return (
    <div className="w-full">
      <h3 className="gradient-text text-[50px] font-extrabold text-center mt-[80px]">
        BOOSTER
      </h3>
      <div className="mt-[220px] flex justify-between items-center px-[200px]">
        <div className="flex flex-col items-center">
          <h1 className="text-[64px] font-extrabold leading-[80px] text-white">
            1R - 3SET
          </h1>
          <h1 className="text-[90px] font-extrabold leading-[110px] mt-[16px] text-white">
            TOTAL 6R
          </h1>
        </div>
        <div className="flex gap-[80px] items-center">
          <div className="flex flex-col items-center">
            <h1 className="text-[60px] font-extrabold leading-[90px] text-white">
              운동시간
            </h1>
            <h1 className="text-[120px] font-extrabold leading-[140px] mt-[10px] gradient-text">
              40SEC
            </h1>
          </div>
          <div className="w-[6px] h-[180px] bg-[#ffffff99]"></div>
          <div className="flex flex-col items-center">
            <h1 className="text-[60px] font-extrabold leading-[90px] text-white">
              휴식시간
            </h1>
            <h1 className="text-[120px] font-extrabold leading-[140px] mt-[10px] gradient-text">
              20SEC
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CircuitDemo;
