import React, { useEffect } from "react";

import ReactDOM from "react-dom";

export interface IModal {
  open?: boolean;
  children: JSX.Element[] | JSX.Element;
  handleClose: () => void;
}

function Modal({ open = true, children, handleClose }: IModal) {
  useEffect(() => {
    const isModalOverlapped =
      document.body.style.left === "0px" &&
      document.body.style.right === "0px" &&
      document.body.style.bottom === "0px" &&
      document.body.style.position === "fixed";
    if (
      open &&
      document &&
      document.body.clientHeight > window.innerHeight &&
      !isModalOverlapped
    ) {
      document.body.style.top = `${-document.documentElement.scrollTop}px`;
      document.body.style.left = "0";
      document.body.style.right = "0";
      document.body.style.bottom = "0";
      document.body.style.position = "fixed";
    }

    return () => {
      if (open && !isModalOverlapped) {
        document.body.style.removeProperty("position");
        document.documentElement.scrollTop =
          Number(document.body.style.top.split("px")[0]) * -1;
        document.body.style.removeProperty("top");
        document.body.style.removeProperty("left");
        document.body.style.removeProperty("right");
        document.body.style.removeProperty("bottom");
      }
    };
  }, [open]);

  return ReactDOM.createPortal(
    <>
      {open && (
        <div
          className="fixed top-0 bottom-0 left-1/2 -translate-x-1/2 z-50 bg-[rgba(0,0,0,0.5)] w-[1920px]"
          onClick={handleClose}
        >
          {children}
        </div>
      )}
    </>,
    document.body
  );
}

export default Modal;
