import React from "react";
import { LogoutIcon } from "./Icons";
import { useAuthContext } from "../context/auth";

function Loading() {
  const { logout } = useAuthContext();
  return (
    <div className="bg-bg flex justify-center items-center h-full w-full relative">
      <img src={"/logo.png"} className="w-[1074px] h-[527px]" alt="logo" />
    </div>
  );
}

export default Loading;
