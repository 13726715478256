export const PersonIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9993 5.89711C16.9113 5.89711 14.408 8.38442 14.408 11.4527C14.408 14.5209 16.9113 17.0082 19.9993 17.0082C23.0874 17.0082 25.5907 14.5209 25.5907 11.4527C25.5907 8.38442 23.0874 5.89711 19.9993 5.89711ZM11.8273 11.4527C11.8273 6.9683 15.4861 3.33301 19.9993 3.33301C24.5126 3.33301 28.1714 6.9683 28.1714 11.4527C28.1714 15.937 24.5126 19.5723 19.9993 19.5723C15.4861 19.5723 11.8273 15.937 11.8273 11.4527Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7763 25.5552C12.1701 25.5552 9.24666 28.4599 9.24666 32.043C9.24666 32.2312 9.28126 32.364 9.31755 32.4413C9.34817 32.5066 9.37445 32.5251 9.39636 32.537C10.3991 33.0792 13.2107 34.1022 19.9993 34.1022C26.788 34.1022 29.5996 33.0792 30.6023 32.537C30.6242 32.5251 30.6505 32.5066 30.6812 32.4413C30.7174 32.364 30.752 32.2312 30.752 32.043C30.752 28.4599 27.8286 25.5552 24.2224 25.5552H15.7763ZM6.66602 32.043C6.66602 27.0438 10.7448 22.9911 15.7763 22.9911H24.2224C29.2539 22.9911 33.3327 27.0438 33.3327 32.043C33.3327 32.9608 32.9983 34.1607 31.8358 34.7892C30.2959 35.6218 26.9924 36.6663 19.9993 36.6663C13.0062 36.6663 9.70278 35.6218 8.16288 34.7892C7.00041 34.1607 6.66602 32.9608 6.66602 32.043Z"
      fill="white"
    />
  </svg>
);

export const LockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8571 9.74326C11.8571 6.20298 14.7353 3.33301 18.2857 3.33301H21.7143C25.2647 3.33301 28.1429 6.20298 28.1429 9.74326V13.9883C28.9816 14.0548 29.7634 14.1222 30.4473 14.184C32.659 14.3838 34.4145 16.1178 34.6154 18.3322C34.7946 20.3079 35 23.0229 35 25.1279C35 27.2328 34.7946 29.9478 34.6154 31.9236C34.4145 34.138 32.659 35.8719 30.4473 36.0718C27.6689 36.3228 23.2771 36.6663 20 36.6663C16.7229 36.6663 12.3311 36.3228 9.55269 36.0718C7.34105 35.8719 5.58554 34.138 5.38463 31.9236C5.20537 29.9478 5 27.2328 5 25.1279C5 23.0229 5.20537 20.3079 5.38463 18.3322C5.58554 16.1178 7.34105 14.3838 9.55269 14.184C10.2366 14.1222 11.0184 14.0548 11.8571 13.9883V9.74326ZM13.2276 16.4555C13.2345 16.4551 13.2413 16.4546 13.2481 16.454C15.5019 16.29 17.9843 16.1535 20 16.1535C22.0157 16.1535 24.4981 16.29 26.7519 16.454C26.7587 16.4546 26.7655 16.4551 26.7724 16.4555C28.0329 16.5474 29.2212 16.6478 30.2152 16.7376C31.199 16.8265 31.9662 17.5913 32.0544 18.5632C32.2328 20.5295 32.4286 23.1466 32.4286 25.1279C32.4286 27.1092 32.2328 29.7262 32.0544 31.6926C31.9662 32.6645 31.199 33.4293 30.2152 33.5181C27.4395 33.769 23.1499 34.1022 20 34.1022C16.8501 34.1022 12.5605 33.769 9.78476 33.5181C8.80104 33.4293 8.03378 32.6645 7.9456 31.6926C7.76719 29.7262 7.57143 27.1092 7.57143 25.1279C7.57143 23.1466 7.76719 20.5295 7.9456 18.5632C8.03378 17.5913 8.80103 16.8265 9.78476 16.7376C10.7788 16.6478 11.9671 16.5474 13.2276 16.4555ZM25.5714 13.8028C23.6669 13.6803 21.6859 13.5894 20 13.5894C18.3141 13.5894 16.3331 13.6803 14.4286 13.8028V9.74326C14.4286 7.61909 16.1555 5.89711 18.2857 5.89711H21.7143C23.8445 5.89711 25.5714 7.61909 25.5714 9.74326V13.8028ZM20 22.1364C20.7101 22.1364 21.2857 22.7104 21.2857 23.4185V26.8373C21.2857 27.5453 20.7101 28.1193 20 28.1193C19.2899 28.1193 18.7143 27.5453 18.7143 26.8373V23.4185C18.7143 22.7104 19.2899 22.1364 20 22.1364Z"
      fill="white"
    />
  </svg>
);

export const ComputerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.0007 5.89711C16.3644 5.89711 11.3876 6.22918 8.30634 6.46597C7.30504 6.54292 6.51738 7.31871 6.42636 8.31165C6.19196 10.8688 5.89809 14.6407 5.89809 17.4356C5.89809 20.2305 6.19196 24.0023 6.42636 26.5595C6.51738 27.5524 7.30503 28.3282 8.30634 28.4052C11.3876 28.642 16.3644 28.974 20.0007 28.974C23.6369 28.974 28.6137 28.642 31.695 28.4052C32.6963 28.3282 33.4839 27.5524 33.5749 26.5595C33.8093 24.0023 34.1032 20.2305 34.1032 17.4356C34.1032 14.6407 33.8093 10.8688 33.5749 8.31165C33.4839 7.31871 32.6963 6.54292 31.695 6.46597C28.6137 6.22918 23.6369 5.89711 20.0007 5.89711ZM8.10987 3.9094C11.1923 3.67253 16.2562 3.33301 20.0007 3.33301C23.7451 3.33301 28.809 3.67253 31.8914 3.9094C34.1325 4.08162 35.9225 5.83219 36.1283 8.07759C36.3632 10.6394 36.6673 14.5131 36.6673 17.4356C36.6673 20.358 36.3632 24.2317 36.1283 26.7936C35.9225 29.039 34.1325 30.7895 31.8914 30.9617C28.809 31.1986 23.7451 31.5381 20.0007 31.5381C16.2562 31.5381 11.1923 31.1986 8.10987 30.9617C5.86883 30.7895 4.07878 29.039 3.87296 26.7936C3.63813 24.2317 3.33398 20.358 3.33398 17.4356C3.33398 14.5131 3.63813 10.6394 3.87296 8.07759C4.07878 5.83219 5.86883 4.08162 8.10987 3.9094Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.7448 12.2555C28.2455 12.7562 28.2455 13.5679 27.7448 14.0686L22.6166 19.1968C22.1159 19.6975 21.3042 19.6975 20.8035 19.1968L18.2913 16.6846L14.0696 20.9062C13.5689 21.4069 12.7572 21.4069 12.2565 20.9062C11.7558 20.4056 11.7558 19.5938 12.2565 19.0931L17.3847 13.9649C17.8854 13.4643 18.6971 13.4643 19.1978 13.9649L21.7101 16.4772L25.9317 12.2555C26.4324 11.7548 27.2441 11.7548 27.7448 12.2555Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.881 35.3843C11.881 34.6762 12.455 34.1022 13.163 34.1022H26.8383C27.5463 34.1022 28.1203 34.6762 28.1203 35.3843C28.1203 36.0924 27.5463 36.6663 26.8383 36.6663H13.163C12.455 36.6663 11.881 36.0924 11.881 35.3843Z"
      fill="white"
    />
  </svg>
);

export const LeftChevron = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
  >
    <path
      d="M29 3L5.2097 20.3852C4.11641 21.1842 4.11641 22.8158 5.2097 23.6148L29 41"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export const RightChevron = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
  >
    <path
      d="M17 41L40.7903 23.6148C41.8836 22.8158 41.8836 21.1842 40.7903 20.3852L17 3"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
    />
  </svg>
);

export const ClockIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="68"
    viewBox="0 0 68 68"
    fill="none"
  >
    <circle
      cx="34"
      cy="38"
      r="25"
      stroke="url(#paint0_linear_2305_5309)"
      stroke-width="4"
    />
    <path
      d="M26 6H42"
      stroke="url(#paint1_linear_2305_5309)"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M34 37L42 46"
      stroke="url(#paint2_linear_2305_5309)"
      stroke-width="4"
      stroke-linecap="round"
    />
    <path
      d="M34 37V24"
      stroke="url(#paint3_linear_2305_5309)"
      stroke-width="4"
      stroke-linecap="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2305_5309"
        x1="7"
        y1="11"
        x2="61"
        y2="65"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BFFF00" />
        <stop offset="1" stop-color="#00D7B0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2305_5309"
        x1="26"
        y1="6"
        x2="26.1245"
        y2="7.99222"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BFFF00" />
        <stop offset="1" stop-color="#00D7B0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2305_5309"
        x1="34"
        y1="37"
        x2="42.9379"
        y2="44.9448"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BFFF00" />
        <stop offset="1" stop-color="#00D7B0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2305_5309"
        x1="34"
        y1="24"
        x2="35.9882"
        y2="24.1529"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#BFFF00" />
        <stop offset="1" stop-color="#00D7B0" />
      </linearGradient>
    </defs>
  </svg>
);

export const Triangle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
  >
    <path d="M40.25 3.5H5.75L23 26.5L40.25 3.5Z" fill="white" />
  </svg>
);

export const Pause = () => (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="74" height="74" rx="37" fill="#333241" />
    <rect x="25" y="23" width="6" height="28" rx="3" fill="white" />
    <rect x="43" y="23" width="6" height="28" rx="3" fill="white" />
  </svg>
);

export const PeopleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="38"
    viewBox="0 0 40 38"
    fill="none"
  >
    <path
      d="M14.8563 16.7126C18.4067 16.7126 21.2849 13.8344 21.2849 10.284C21.2849 6.73364 18.4067 3.85547 14.8563 3.85547C11.3059 3.85547 8.42773 6.73364 8.42773 10.284C8.42773 13.8344 11.3059 16.7126 14.8563 16.7126Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M3.28516 34.7138H14.8566H26.428V33.3198C26.4075 31.3598 25.8906 29.437 24.9256 27.731C23.9605 26.0249 22.5788 24.5913 20.9095 23.5641C19.2402 22.5368 17.3378 21.9494 15.3799 21.8567C15.2054 21.8484 15.0309 21.8441 14.8566 21.8438C14.6823 21.8441 14.5078 21.8484 14.3333 21.8567C12.3754 21.9494 10.4729 22.5368 8.80363 23.5641C7.13433 24.5913 5.75262 26.0249 4.78757 27.731C3.82253 29.437 3.30563 31.3598 3.28516 33.3198V34.7138Z"
      stroke="white"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M25.1426 16.7126C28.693 16.7126 31.5711 13.8344 31.5711 10.284C31.5711 6.73364 28.693 3.85547 25.1426 3.85547"
      stroke="white"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M31.572 34.7124H36.7148V33.3183C36.6944 31.3584 36.1775 29.4356 35.2124 27.7296C34.2474 26.0235 32.8657 24.5899 31.1964 23.5627C30.1173 22.8986 28.9408 22.4183 27.7148 22.1367"
      stroke="white"
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const ComputerIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="37"
    viewBox="0 0 36 37"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 5.80769C14.7273 5.80769 10.2482 6.10656 7.47512 6.31967C6.57395 6.38892 5.86505 7.08714 5.78314 7.98077C5.57218 10.2822 5.30769 13.6769 5.30769 16.1923C5.30769 18.7077 5.57218 22.1024 5.78314 24.4038C5.86505 25.2975 6.57395 25.9957 7.47512 26.065C10.2482 26.2781 14.7273 26.5769 18 26.5769C21.2727 26.5769 25.7518 26.2781 28.5249 26.065C29.4261 25.9957 30.1349 25.2975 30.2169 24.4038C30.4278 22.1024 30.6923 18.7077 30.6923 16.1923C30.6923 13.6769 30.4278 10.2822 30.2169 7.98077C30.1349 7.08714 29.4261 6.38892 28.5249 6.31967C25.7518 6.10656 21.2727 5.80769 18 5.80769ZM7.2983 4.01876C10.0725 3.80557 14.63 3.5 18 3.5C21.37 3.5 25.9275 3.80557 28.7017 4.01876C30.7186 4.17375 32.3297 5.74926 32.5149 7.77013C32.7263 10.0758 33 13.5621 33 16.1923C33 18.8225 32.7263 22.3088 32.5149 24.6145C32.3297 26.6354 30.7186 28.2109 28.7017 28.3659C25.9275 28.579 21.37 28.8846 18 28.8846C14.63 28.8846 10.0725 28.579 7.2983 28.3659C5.28136 28.2109 3.67032 26.6354 3.48508 24.6145C3.27373 22.3088 3 18.8225 3 16.1923C3 13.5621 3.27373 10.0758 3.48508 7.77013C3.67032 5.74926 5.28136 4.17375 7.2983 4.01876Z"
      fill="#BFFF00"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.9697 11.5303C25.4203 11.9809 25.4203 12.7114 24.9697 13.162L20.3544 17.7774C19.9037 18.228 19.1732 18.228 18.7226 17.7774L16.4615 15.5164L12.662 19.3159C12.2114 19.7665 11.4809 19.7665 11.0303 19.3159C10.5797 18.8653 10.5797 18.1347 11.0303 17.6841L15.6456 13.0687C16.0963 12.6181 16.8268 12.6181 17.2774 13.0687L19.5385 15.3298L23.338 11.5303C23.7886 11.0797 24.5191 11.0797 24.9697 11.5303Z"
      fill="#BFFF00"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.6923 32.3462C10.6923 31.7089 11.2089 31.1923 11.8462 31.1923H24.1538C24.7911 31.1923 25.3077 31.7089 25.3077 32.3462C25.3077 32.9834 24.7911 33.5 24.1538 33.5H11.8462C11.2089 33.5 10.6923 32.9834 10.6923 32.3462Z"
      fill="#BFFF00"
    />
  </svg>
);

export const ComputerIcon3 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 3.53846C9.81823 3.53846 6.83216 3.73771 4.98341 3.87978C4.38263 3.92595 3.91003 4.39142 3.85543 4.98718C3.71478 6.52149 3.53846 8.78459 3.53846 10.4615C3.53846 12.1385 3.71478 14.4016 3.85543 15.9359C3.91003 16.5317 4.38263 16.9971 4.98341 17.0433C6.83216 17.1854 9.81823 17.3846 12 17.3846C14.1818 17.3846 17.1678 17.1854 19.0166 17.0433C19.6174 16.9971 20.09 16.5317 20.1446 15.9359C20.2852 14.4016 20.4615 12.1385 20.4615 10.4615C20.4615 8.78459 20.2852 6.52149 20.1446 4.98718C20.09 4.39142 19.6174 3.92595 19.0166 3.87978C17.1678 3.73771 14.1818 3.53846 12 3.53846ZM4.86553 2.34584C6.715 2.20371 9.75334 2 12 2C14.2467 2 17.285 2.20371 19.1345 2.34584C20.4791 2.44917 21.5531 3.49951 21.6766 4.84675C21.8175 6.38385 22 8.70808 22 10.4615C22 12.215 21.8175 14.5392 21.6766 16.0763C21.5531 17.4236 20.4791 18.4739 19.1345 18.5772C17.285 18.7194 14.2467 18.9231 12 18.9231C9.75334 18.9231 6.715 18.7194 4.86553 18.5772C3.52091 18.4739 2.44688 17.4236 2.32339 16.0763C2.18249 14.5392 2 12.215 2 10.4615C2 8.70808 2.18249 6.38385 2.32339 4.84675C2.44688 3.49951 3.52091 2.44917 4.86553 2.34584Z"
      fill="#03030F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6465 7.35351C16.9469 7.65391 16.9469 8.14096 16.6465 8.44136L13.5696 11.5183C13.2692 11.8187 12.7821 11.8187 12.4817 11.5183L10.9744 10.0109L8.44136 12.5439C8.14096 12.8443 7.65391 12.8443 7.35351 12.5439C7.0531 12.2435 7.0531 11.7565 7.35351 11.4561L10.4304 8.37915C10.7308 8.07875 11.2179 8.07875 11.5183 8.37915L13.0256 9.8865L15.5586 7.35351C15.859 7.0531 16.3461 7.0531 16.6465 7.35351Z"
      fill="#03030F"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.12821 21.2308C7.12821 20.8059 7.4726 20.4615 7.89744 20.4615H16.1026C16.5274 20.4615 16.8718 20.8059 16.8718 21.2308C16.8718 21.6556 16.5274 22 16.1026 22H7.89744C7.4726 22 7.12821 21.6556 7.12821 21.2308Z"
      fill="#03030F"
    />
  </svg>
);

export const RowingIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.4269 21.429C10.8471 21.429 11.9983 20.2777 11.9983 18.8576C11.9983 17.4374 10.8471 16.2861 9.4269 16.2861C8.00674 16.2861 6.85547 17.4374 6.85547 18.8576C6.85547 20.2777 8.00674 21.429 9.4269 21.429Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M0.855469 18.8574H6.85547"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 18.8574H23.1429"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.7148 15.4287L23.1434 18.8573L19.7148 22.2859"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.42969 16.2853V1.71387"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.42969 1.71387L17.144 5.99958L9.42969 10.2853V1.71387Z"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const RowingIcon2 = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M14.1423 32.143C16.2725 32.143 17.9994 30.4161 17.9994 28.2859C17.9994 26.1556 16.2725 24.4287 14.1423 24.4287C12.0121 24.4287 10.2852 26.1556 10.2852 28.2859C10.2852 30.4161 12.0121 32.143 14.1423 32.143Z"
      stroke="#BFFF00"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.28516 28.2861H10.2852"
      stroke="#BFFF00"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18 28.2861H34.7143"
      stroke="#BFFF00"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M29.5703 23.1426L34.7132 28.2854L29.5703 33.4283"
      stroke="#BFFF00"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1445 24.4284V2.57129"
      stroke="#BFFF00"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.1445 2.57129L25.716 8.99986L14.1445 15.4284V2.57129Z"
      stroke="#BFFF00"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export const LogoutIcon = ({ className }: { className?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={className}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.07692 5.92648C5.07692 4.38908 6.33971 3.14278 7.89744 3.14278H12C12.4248 3.14278 12.7692 3.48268 12.7692 3.90197C12.7692 4.32126 12.4248 4.66116 12 4.66116H7.89744C7.18938 4.66116 6.61539 5.22766 6.61539 5.92648V6.93873C6.61539 7.35802 6.27099 7.69792 5.84615 7.69792C5.42132 7.69792 5.07692 7.35802 5.07692 6.93873V5.92648ZM5.84615 16.3021C6.27099 16.3021 6.61539 16.642 6.61539 17.0613V18.0735C6.61539 18.7723 7.18938 19.3388 7.89744 19.3388H12C12.4248 19.3388 12.7692 19.6788 12.7692 20.098C12.7692 20.5173 12.4248 20.8572 12 20.8572H7.89744C6.33971 20.8572 5.07692 19.6109 5.07692 18.0735V17.0613C5.07692 16.642 5.42132 16.3021 5.84615 16.3021Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7692 4.78618C12.7692 3.90491 13.6592 3.29355 14.4966 3.59962L19.6248 5.47416C20.1277 5.658 20.4615 6.13139 20.4615 6.66071V17.3393C20.4615 17.8686 20.1277 18.342 19.6248 18.5258L14.4966 20.4004C13.6592 20.7065 12.7692 20.0951 12.7692 19.2138V4.78618ZM15.0309 2.17576C13.1887 1.5024 11.2308 2.84738 11.2308 4.78618V19.2138C11.2308 21.1526 13.1887 22.4976 15.0309 21.8242L20.1591 19.9497C21.2656 19.5452 22 18.5038 22 17.3393V6.66071C22 5.4962 21.2656 4.45475 20.1591 4.0503L15.0309 2.17576Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.32787 9.43867C6.62827 9.14218 7.11532 9.14218 7.41572 9.43867L9.46701 11.4632C9.76741 11.7597 9.76741 12.2403 9.46701 12.5368L7.41572 14.5613C7.11532 14.8578 6.62827 14.8578 6.32787 14.5613C6.02746 14.2649 6.02746 13.7842 6.32787 13.4877L7.06599 12.7592H2.76923C2.3444 12.7592 2 12.4193 2 12C2 11.5807 2.3444 11.2408 2.76923 11.2408H7.06599L6.32787 10.5123C6.02746 10.2158 6.02746 9.73515 6.32787 9.43867Z"
      fill="white"
    />
  </svg>
);

export const Logout = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.6923 14.8162C12.6923 10.9727 15.8493 7.85694 19.7436 7.85694H30C31.0621 7.85694 31.9231 8.70669 31.9231 9.75492C31.9231 10.8031 31.0621 11.6529 30 11.6529H19.7436C17.9734 11.6529 16.5385 13.0692 16.5385 14.8162V17.3468C16.5385 18.3951 15.6775 19.2448 14.6154 19.2448C13.5533 19.2448 12.6923 18.3951 12.6923 17.3468V14.8162ZM14.6154 40.7552C15.6775 40.7552 16.5385 41.605 16.5385 42.6532V45.1838C16.5385 46.9309 17.9734 48.3471 19.7436 48.3471H30C31.0621 48.3471 31.9231 49.1969 31.9231 50.2451C31.9231 51.2933 31.0621 52.1431 30 52.1431H19.7436C15.8493 52.1431 12.6923 49.0273 12.6923 45.1838V42.6532C12.6923 41.605 13.5533 40.7552 14.6154 40.7552Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M31.9231 11.9654C31.9231 9.76227 34.148 8.23387 36.2414 8.99906L49.0619 13.6854C50.3192 14.145 51.1538 15.3285 51.1538 16.6518V43.3482C51.1538 44.6715 50.3193 45.8549 49.0619 46.3145L36.2414 51.0009C34.1481 51.7661 31.9231 50.2377 31.9231 48.0346V11.9654ZM37.5772 5.43941C32.9719 3.75599 28.0769 7.11846 28.0769 11.9654V48.0346C28.0769 52.8815 32.9719 56.244 37.5772 54.5606L50.3977 49.8742C53.1639 48.8631 55 46.2594 55 43.3482V16.6518C55 13.7405 53.1639 11.1369 50.3977 10.1257L37.5772 5.43941Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8197 23.5967C16.5707 22.8555 17.7883 22.8555 18.5393 23.5967L23.6675 28.6579C24.4185 29.3991 24.4185 30.6009 23.6675 31.3421L18.5393 36.4034C17.7883 37.1446 16.5707 37.1446 15.8197 36.4034C15.0687 35.6621 15.0687 34.4604 15.8197 33.7192L17.665 31.898H6.92308C5.86099 31.898 5 31.0482 5 30C5 28.9518 5.86099 28.102 6.92308 28.102H17.665L15.8197 26.2808C15.0687 25.5396 15.0687 24.3379 15.8197 23.5967Z"
      fill="white"
    />
  </svg>
);
