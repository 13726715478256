import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 10000,
});

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.access_token = token ? `${token}` : "";
  return config;
});

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("did");
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

export default instance;
