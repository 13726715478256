import React, { useEffect, useState } from "react";
import Login from "./components/Login";

import { useTimer } from "react-use-precision-timer";

import { useAuthContext } from "./context/auth";
import PortraitDefault from "./components/PortraitDefault";
import LandscapeDefault from "./components/LandscapeDefault";
import IceBreaking from "./components/Programs/IceBreaking/IceBreaking";
import DynamicStretching from "./components/Programs/DynamicStretching/DynamicStretching";
import LandscapeDemo from "./components/Programs/Demo/LandscapeDemo";
import PortraitDemoA from "./components/Programs/Demo/PortraitDemoA";
import WorkoutType1 from "./components/Programs/Workout/WorkoutType1";
import WorkoutType2 from "./components/Programs/Workout/WorkoutType2";
import LandscapeTeamWorkout from "./components/Programs/TeamWorkout/LandscapeTeamWorkout";
import Admin from "./components/Admin/Admin";
import Start from "./components/Programs/Start/Start";
import Loading from "./components/Loading";
import PortraitDemoB from "./components/Programs/Demo/PortraitDemoB";
import Rest from "./components/Rest";
import PortraitTeamWorkout from "./components/Programs/TeamWorkout/PortraitTeamWorkout";
import CoolDown from "./components/Programs/CoolDown/CoolDown";
import LoadingPortrait from "./components/LoadingPortrait";
import WorkoutType3 from "./components/Programs/Workout/WorkoutType3";
import LandscapeRow from "./components/Programs/Row/LandscapeRow";
import PortraitRow from "./components/Programs/Row/PortraitRow";

function App() {
  const {
    isAuthenticated,
    loading,
    did,
    step,
    circuitProgram,
    weightProgram,
    setStep,
    row,
  } = useAuthContext();
  if (loading)
    return did === "1" || did === "0" ? <Loading /> : <LoadingPortrait />;
  if (!isAuthenticated) return <Login />;

  const getScreen = () => {
    if (did === "0" && step === 0) {
      return <Admin />;
    }
    const firstWeight = <PortraitDemoA />;
    const secondWeight = <PortraitDemoB />;

    const firstCircuit =
      did === "L1" || did === "L6" ? <PortraitDemoA /> : <PortraitDemoB />;
    const secondCircuit =
      did === "L1" || did === "L6" ? <PortraitDemoA /> : <PortraitDemoB />;

    const samePages = [
      did === "1" || did === "0" ? <LandscapeDefault /> : <PortraitDefault />,
      did === "1" || did === "0" ? <Start /> : <PortraitDefault />,
      did === "1" || did === "0" ? <IceBreaking /> : <PortraitDefault />,
      did === "1" || did === "0" ? (
        <Rest nowStep={2} time={4} />
      ) : (
        <PortraitDefault />
      ),
      did === "1" || did === "0" ? <DynamicStretching /> : <PortraitDefault />,
    ];

    const otherSamePages = [
      did === "1" || did === "0" ? <CoolDown /> : <PortraitDefault />,
    ];

    const teamWorkOuts =
      circuitProgram?.isTeamWorkOutAvailable === false ||
      weightProgram?.isTeamWorkOutAvailable === false
        ? []
        : [
            row === undefined ? (
              did === "1" || did === "0" ? (
                <LandscapeTeamWorkout />
              ) : (
                <PortraitTeamWorkout />
              )
            ) : did === "1" || did === "0" ? (
              <LandscapeRow />
            ) : (
              <PortraitRow />
            ),
          ];

    const weightPages = [
      ...samePages,
      did === "1" || did === "0" ? (
        <LandscapeDemo />
      ) : did === "L1" || did === "L6" ? (
        firstWeight
      ) : (
        secondWeight
      ),
      did === "1" || did === "0" ? <Rest nowStep={4} time={4} /> : firstWeight,
      did === "1" || did === "0" ? <WorkoutType1 /> : firstWeight,
      did === "1" || did === "0" ? <Rest nowStep={4} /> : firstWeight,
      did === "1" || did === "0" ? <WorkoutType1 secondRound /> : firstWeight,
      did === "1" || did === "0" ? <Rest nowStep={4} /> : secondWeight,
      did === "1" || did === "0" ? <WorkoutType1 /> : secondWeight,
      did === "1" || did === "0" ? <Rest nowStep={4} /> : secondWeight,
      did === "1" || did === "0" ? <WorkoutType1 secondRound /> : secondWeight,
      ...teamWorkOuts,
      ...otherSamePages,
    ];

    const circuitPages = [
      ...samePages,
      did === "1" || did === "0" ? <LandscapeDemo /> : firstCircuit,
      did === "1" || did === "0" ? <Rest nowStep={4} time={4} /> : firstCircuit,
      did === "1" || did === "0" ? <WorkoutType2 /> : firstCircuit,
      did === "1" || did === "0" ? <Rest nowStep={4} /> : secondCircuit,
      did === "1" || did === "0" ? <WorkoutType2 second /> : secondCircuit,
      ...teamWorkOuts,
      ...otherSamePages,
    ];

    const newCircuitPages = [
      ...samePages,
      did === "1" || did === "0" ? <LandscapeDemo /> : firstCircuit,
      did === "1" || did === "0" ? <Rest nowStep={4} time={4} /> : firstCircuit,
      did === "1" || did === "0" ? (
        <WorkoutType3 round={1} key={11} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={2} key={22} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={3} key={33} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <Rest nowStep={4} time={60} />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={1} key={21} second />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={2} key={22} second />
      ) : (
        firstCircuit
      ),
      did === "1" || did === "0" ? (
        <WorkoutType3 round={3} key={23} second />
      ) : (
        firstCircuit
      ),
      ...teamWorkOuts,
      ...otherSamePages,
    ];

    if (
      circuitProgram?.programType === "BOOSTER"
        ? circuitPages.length <= step
        : circuitProgram?.programType === "CIRCUIT"
        ? newCircuitPages.length <= step
        : weightPages.length <= step
    ) {
      setStep(0);
      return did === "1" || did === "0" ? (
        <LandscapeDefault />
      ) : (
        <PortraitDefault />
      );
    }

    return circuitProgram?.programType === "BOOSTER"
      ? circuitPages[step]
      : circuitProgram?.programType === "CIRCUIT"
      ? newCircuitPages[step]
      : weightPages[step];
  };
  return <>{getScreen()}</>;
}

export default App;
