import React from "react";
import { ClockIcon, LeftChevron, RightChevron } from "../Icons";
import { useAuthContext } from "../../context/auth";
import { TimerRenderer } from "react-use-precision-timer";

function BottomNav() {
  const { timer, sendSetStep, step } = useAuthContext();
  return (
    <>
      <div className="flex-1" />
      <div className="flex w-full h-[132px]">
        <div
          className="w-[132px] h-[132px] flex justify-center items-center bg-[#48505F]"
          onClick={() => {
            sendSetStep(step - 1);
          }}
        >
          <LeftChevron />
        </div>
        <div className="flex-1 flex justify-center items-center h-full relative">
          <button
            className="absolute left-[70px] text-[40px] font-bold text-white"
            onClick={() => {
              if (window.confirm("정말로 첫 화면으로 돌아가시겠습니까?"))
                sendSetStep(0);
            }}
          >
            첫 화면으로
          </button>
          <div className="flex gap-4 items-center">
            <ClockIcon />
            <TimerRenderer
              timer={timer}
              render={(t) => (
                <>
                  <div className="flex">
                    <p className="gradient-text text-[64px]">
                      {String(
                        Math.floor(t.getElapsedRunningTime() / 1000 / 60)
                      ).padStart(2, "0")}
                    </p>
                    <p className="gradient-text text-[64px]">:</p>
                    <p className="gradient-text text-[64px]">
                      {String(
                        Math.floor((t.getElapsedRunningTime() / 1000) % 60)
                      ).padStart(2, "0")}
                    </p>
                  </div>
                </>
              )}
              renderRate={500} // In milliseconds
            />
          </div>
        </div>
        <div
          className="w-[132px] h-[132px] flex justify-center items-center bg-[#48505F]"
          onClick={() => {
            sendSetStep(step + 1);
          }}
        >
          <RightChevron />
        </div>
      </div>
    </>
  );
}

export default BottomNav;
