import { useEffect, useState } from "react";
// @ts-ignore
import useSound from "use-sound";
// @ts-ignore
import countdown from "../countdown.mp3";
import { useAuthContext } from "../context/auth";

const useCountDown = (
  initialValue: number,
  callback: () => void,
  playSound?: boolean
) => {
  const { did } = useAuthContext();
  const [count, setCount] = useState(initialValue);
  const [isRunning, setIsRunning] = useState(false);
  const [play] = useSound(countdown, {
    volume: 1,
  });

  const start = () => {
    setIsRunning(true);
  };

  const stop = () => {
    setIsRunning(false);
  };

  const reset = () => {
    setCount(initialValue);
  };

  useEffect(() => {
    if (isRunning) {
      const timer = setInterval(() => {
        setCount((prevCount) => {
          console.log(prevCount);
          if (prevCount === 4) {
            if (playSound) {
              console.log(did, playSound);
              did === "1" && play();
            }
          }
          if (prevCount === 0) {
            callback();
            return initialValue;
          }
          return prevCount - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isRunning, initialValue, play]);

  return { count, start, stop, reset, stopped: !isRunning };
};

export default useCountDown;
