import React, { useState } from "react";
import Input from "./Input";
import { ComputerIcon, LockIcon, PersonIcon } from "./Icons";
import { useAuthContext } from "../context/auth";

function Login() {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [did, setDid] = useState("");
  const { login } = useAuthContext();

  return (
    <div className="bg-bg flex justify-center items-center h-full w-full">
      <form
        className="flex flex-col items-center gap-[60px]"
        onSubmit={(e) => {
          e.preventDefault();
          login(id, password, did);
        }}
      >
        <img src={"/logo.png"} className="w-[376px] h-[184px]" alt="logo" />
        <div className="flex flex-col gap-4">
          <Input
            placeholder="아이디"
            icon={<PersonIcon />}
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
          <Input
            placeholder="비밀번호"
            icon={<LockIcon />}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            className="placeholder:font-[TheJamsil5Bold]"
            style={{
              fontFamily: "sans-serif",
            }}
          />
          <Input
            placeholder="DID번호"
            icon={<ComputerIcon />}
            value={did}
            onChange={(e) => setDid(e.target.value)}
          />
        </div>
        <button
          className="rounded-[4px] text-2xl text-black p-6 flex justify-center items-center w-full"
          style={{
            background:
              "linear-gradient(270deg, #74FF33 0%, #74FF33 0.01%, #00EEC3 100%)",
          }}
        >
          로그인하기
        </button>
      </form>
    </div>
  );
}

export default Login;
