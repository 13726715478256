import React from "react";
import cn from "../../utils/cn";
import { useAuthContext } from "../../context/auth";

function LandscapeProgramLayout({
  children,
  step,
}: {
  children: React.ReactNode;
  step: number;
}) {
  const { circuitProgram, weightProgram, did } = useAuthContext();
  return (
    <div className="h-full w-full bg-bg  flex flex-col">
      {did === "0" && (
        <div className="flex flex-row text-2xl font-bold text-white">
          <div
            className={cn(
              "flex-1  py-4 flex justify-center items-center",
              step === 1 && "bg-[#48505F]"
            )}
          >
            1. ICE BREAKING
          </div>
          <div
            className={cn(
              "flex-1 px-[10px] py-4 flex justify-center items-center",
              step === 2 && "bg-[#48505F]"
            )}
          >
            2. DYNAMIC STRETCHING
          </div>
          <div
            className={cn(
              "px-[60px] py-4 flex justify-center items-center",
              step === 3 && "bg-[#48505F]"
            )}
          >
            3. DEMO
          </div>
          <div
            className={cn(
              "px-[60px] py-4 flex justify-center items-center",
              step === 4 && "bg-[#48505F]"
            )}
          >
            4. WORK OUT
          </div>
          <div
            className={cn(
              "flex-1  py-4 flex justify-center items-center",
              step === 5 && "bg-[#48505F]"
            )}
          >
            5. TEAM WORK OUT
          </div>
          <div
            className={cn(
              "flex-1  py-4 flex justify-center items-center",
              step === 6 && "bg-[#48505F]"
            )}
          >
            6. COOL DOWN
          </div>
        </div>
      )}
      {children}
    </div>
  );
}

export default LandscapeProgramLayout;
