import React, { useCallback, useEffect, useState } from "react";
import LandscapeProgramLayout from "../LandscapeProgramLayout";
import cn from "../../../utils/cn";
import BottomNav from "../BottomNav";
import { useAuthContext } from "../../../context/auth";
import useCountDown from "../../../hooks/useCountDown";
import dayjs from "dayjs";
import CircularProgress from "../../CircularProgress";

function DynamicStretching() {
  const { circuitProgram, weightProgram, startTime, sendSetStep, step } =
    useAuthContext();
  const program = circuitProgram ?? weightProgram;

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [step1, setStep1] = useState(0);
  const { count, start } = useCountDown(
    20,
    useCallback(() => {
      setStep1((prev) => {
        if (program?.warmUpMovements.length === prev + 1) {
          sendSetStep(step + 1);
          return 0;
        }
        return prev + 1;
      });
    }, [program, sendSetStep, step]),
    true
  );

  useEffect(() => {
    setTimeout(() => {
      start();
      videoRef.current?.play();
    }, startTime.current - dayjs().valueOf());
  }, [startTime, start]);

  if (!program) return <></>;
  const nowDynamicStretching = program.warmUpMovements[step1];

  return (
    <LandscapeProgramLayout step={2}>
      <div className="flex h-full">
        <div className="min-w-[364px] h-full">
          <p className="gradient-text uppercase italic text-[32px] font-bold mt-[70px] ml-[50px]">
            STEP
          </p>
          <div className="flex flex-col w-full pl-[30px] gap-2 mt-[68px]">
            {program.warmUpMovements.map((movement, index) => (
              <div
                className={cn(
                  "rounded-l-xl px-12 py-4 text-[32px] text-[#48505F] whitespace-nowrap",
                  index === step1 && "bg-white text-black"
                )}
                key={index}
              >
                {index + 1}. {movement.name}
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 bg-white h-full relative">
          <video
            className="w-full h-full object-cover"
            ref={videoRef}
            loop
            muted
            key={nowDynamicStretching.video}
          >
            <source src={nowDynamicStretching.video} type="video/mp4" />
          </video>
          <div className="absolute bottom-10 right-10 w-[310px] h-[310px]">
            <CircularProgress maxValue={40} value={count} />
          </div>
        </div>
      </div>
      <BottomNav />
    </LandscapeProgramLayout>
  );
}

export default DynamicStretching;
