import instance from "./instance";

const login = async (username: string, password: string, did: string) => {
  const { data } = await instance.post(`/login`, { username, password });
  return data;
};

const logout = async () => {
  await instance.post(`/logout`);
};

const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const { data } = await instance.post(`/refresh-token`, { refreshToken });
  return data;
};

const authApi = {
  login,
  logout,
  refreshToken,
};
export default authApi;
