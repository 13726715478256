import React from "react";
import { LogoutIcon } from "./Icons";
import { useAuthContext } from "../context/auth";

function LandscapeDefault() {
  const { logout } = useAuthContext();
  return (
    <div className="bg-bg flex justify-center items-center h-full w-full relative">
      <button className="absolute top-0 right-0 m-10" onClick={() => logout()}>
        <LogoutIcon className="w-[60px] h-[60px]" />
      </button>
      <img src={"/logo.png"} className="w-[1074px] h-[527px]" alt="logo" />
    </div>
  );
}

export default LandscapeDefault;
