import React from "react";

const NewCircuitDemo = () => {
  return (
    <div className="w-full flex flex-col items-center">
      <h3 className="gradient-text text-[50px] font-extrabold text-center mt-[80px] uppercase">
        circuit training
      </h3>
      <div className="mt-[120px] flex flex-col gap-[40px] items-end">
        <div className="px-[46px] flex gap-[198px]">
          <p className="text-[40px] font-bold text-white">운동시간</p>{" "}
          <p className="text-[40px] font-bold text-white">휴식시간</p>
        </div>
        <div className="flex flex-col gap-[80px]">
          <div className="flex items-end justify-between w-[1140px]">
            <h1 className="text-[64px] font-extrabold leading-[80px] text-white">
              1R - 6set
            </h1>
            <div className="flex gap-[50px] items-center">
              <h1 className="text-[70px] font-extrabold leading-[90px] gradient-text w-[240px] text-center">
                1MIN
              </h1>
              <div className="w-[6px] h-[60px] bg-[#ffffff99]"></div>
              <h1 className="text-[70px] font-extrabold leading-[90px] gradient-text w-[240px] text-center">
                30SEC
              </h1>
            </div>
          </div>
          <div className="flex items-end justify-between w-[1140px]">
            <h1 className="text-[64px] font-extrabold leading-[80px] text-white">
              2R - 6set
            </h1>
            <div className="flex gap-[50px] items-center">
              <h1 className="text-[70px] font-extrabold leading-[90px] gradient-text w-[240px] text-center">
                40SEC
              </h1>
              <div className="w-[6px] h-[60px] bg-[#ffffff99]"></div>
              <h1 className="text-[70px] font-extrabold leading-[90px] gradient-text w-[240px] text-center">
                20SEC
              </h1>
            </div>
          </div>
          <div className="flex items-end justify-between w-[1140px]">
            <h1 className="text-[64px] font-extrabold leading-[80px] text-white">
              3R - 6set
            </h1>
            <div className="flex gap-[50px] items-center">
              <h1 className="text-[70px] font-extrabold leading-[90px] gradient-text w-[240px] text-center">
                20SEC
              </h1>
              <div className="w-[6px] h-[60px] bg-[#ffffff99]"></div>
              <h1 className="text-[70px] font-extrabold leading-[90px] gradient-text w-[240px] text-center">
                10SEC
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCircuitDemo;
